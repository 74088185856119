@import 'assets/global';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}

html, body {
  height: 100%;
}

body {
  //background: #181D28;
  background: #000000;
  margin: 0;
  padding: 0;
  mat-icon.mat-icon {
    width: auto;
    height: auto;
  }
  .mat-radio-button, .mat-checkbox {
    font-family: 'Open Sans', sans-serif;
  }
}

.row {
  margin: 0;
  padding: 0;
}

