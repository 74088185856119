@import "mixins";
$white: #EFF1F9;
$gray: #D9D9D9;
$black: #181D28;
$gold: #F2BB6C;

.support {
  position: fixed;
  right: 70px;
  bottom: 55px;
  z-index: 99;

  &__panel {
    border-radius: 20px !important;
    border: 1px solid #5B99FA;
    background: linear-gradient(92deg, rgba(144, 144, 144, 0.17) -22.19%, rgba(113, 133, 245, 0.30) 11.44%, rgba(255, 255, 255, 0.17) 133.1%, rgba(144, 144, 144, 0.17) 148.92%);
    backdrop-filter: blur(22px);
    color: $white;
    font-family: Manrope, sans-serif;

    .mat-mdc-menu-content, .mdc-list {
      font-family: Manrope, sans-serif !important;
    }
  }

  &__trigger {
    padding: 22px 12px;
    cursor: pointer;
  }

  &__btn {
    border-radius: 8px;
    border: 1px solid $white;
    background: transparent;
    font-size: 18px;
    font-weight: 800;
    color: $white;
    margin: auto;
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  &__dialogsMenu {
    bottom: -114px;
    width: 338px;
    max-width: 338px !important;
    height: 460px;
    z-index: 999;

    .mat-mdc-menu-content {
      padding: 0;
      height: 100%;
    }
  }

  &__menuInner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__dialogsList {
    max-height: 310px;
    height: 240px;
  }

  &__dialog {
    padding: 13px 25px 11px 30px;
    display: flex;
    align-items: flex-start;
    background: transparent;
    transition: background .1s ease;
    cursor: pointer;

    &:hover,
    &--active {
      background: rgba(239, 241, 249, 0.20);
    }

    & > img {
      margin-right: 15px;
      margin-top: 3px;
    }

    &Text {
      font-family: Poppins, sans-serif;
      margin-right: 15px;
    }

    &Date {
      font-size: 15px;
      font-style: italic;
    }
  }

  &__unread {
    background: $white;
    color: $black;
    padding: 2px 8px;
    font-size: 15px;
    font-weight: 800;
    border-radius: 3px;
    text-align: center;
    margin-left: auto;

    &--floating {
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }

  &__title {
    border-bottom: 1px solid rgba(217, 217, 217, 0.50);
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
    pointer-events: none;
  }

  &__close {
    border-top: 1px solid rgba(217, 217, 217, 0.50);
    padding: 24px 24px 24px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;

    span {
      margin: 0 10px 0 13px;
    }
  }

  &__chat {
    &Trigger {
      position: absolute;
    }

    &Menu {
      width: 586px;
      max-width: 100vw !important;
      height: 460px;
      margin-right: 5px !important;

      .mat-mdc-menu-content {
        padding: 0;
        height: 100%;
      }
    }

    &Close {
      padding: 4px;
      margin-left: 20px;

      &--mobile {
        margin-left: 0;
        margin-right: 8px;
        padding: 4px 0;
      }
    }
  }

  &__messageList {
    height: 318px;
    max-height: 318px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
  }

  &__start {
    margin: auto;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-weight: 800;
  }

  &__input {
    padding: 10px 15px 15px;

    mat-form-field {
      width: 100%;

      .mdc-notched-outline > div {
        border-color: $gray !important;
      }

      .mdc-notched-outline__leading {
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
      }

      .mdc-notched-outline__trailing {
        border-top-right-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
      }

      input {
        color: $white !important;
        caret-color: $white !important;
      }

      input::placeholder {
        color: $gray !important;
      }

      .mat-mdc-form-field-icon-suffix {
        padding: 0 10px 0 10px;
      }

      button {
        background: transparent;
        border: none;
        margin-right: 15px;
      }
    }

    &.support__input-empty {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 15px 10px 25px;

    span {
      margin-right: auto;
      font-family: Poppins, sans-serif;
      font-size: 20px;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: #0E962C;
        border-radius: 8px;
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 3px;
      }
    }

    button {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      color: $white;
    }
  }

  &__assistance {
    font-family: Manrope, sans-serif;
    font-weight: 800;

    img {
      margin-right: 12px;
      height: 12px;
    }
  }

  &__rate {
    height: 409px;
  }

  &__mail {
    filter: brightness(5);
  }

  &--scrollable {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $white;
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 1000px) {
    right: 16px;
    bottom: 40px;

    &__trigger {
      padding: 9px 5px;

      img {
        width: 44px;
      }
    }

    &__dialogsMenu {
      bottom: -50px;
    }

    &__header {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #D9D9D9;

      span {
        font-size: 14px;

        &::before {
          margin-bottom: 1px;
        }
      }
    }

    &__assistance {
      width: 90px;
      font-size: 12px;
      line-height: normal;
    }

    &__messageList {
      height: 312px;
      max-height: 312px;
    }

    &__start {
      font-size: 16px;
    }

    &__rate {
      height: 393px;
    }

    &__unread--floating {
      font-size: 12px;
      top: -8px;
      right: -8px;
      padding: 0px 6px;
    }
  }
}

.message {
  display: flex;
  font-family: Poppins, sans-serif;
  margin-bottom: 15px;
  gap: 15px;
  padding-right: 59px;

  &__avatar {
    width: 44px;
    min-width: 44px;
    height: 44px;
    border-radius: 44px;
    overflow: hidden;
    margin-top: 31px;
    background: transparentize($white, .1) url(/assets/icons/help.svg) center center no-repeat;
  }

  &__sender,
  &__date {
    color: $gray;
    font-size: 13px;
    text-align: left;
  }

  &__sender {
    margin-bottom: 4px;
  }

  &__text {
    background: transparentize($white, .1);
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 8px;
    color: $black;
  }

  &--sent {
    flex-direction: row-reverse;
    padding-left: 59px;
    padding-right: 0;

    .message__sender {
      text-align: right;
    }

    .message__avatar {
      background-image: url(/assets/icons/user.svg);
    }
  }

  @media screen and (max-width: 1000px) {
    padding-right: 40px;

    &__avatar {
      height: 25px;
      width: 25px;
      min-width: 25px;
      background-size: 13px;
      margin-top: 37px;
    }

    &__sender,
    &__date {
      font-size: 10px;
    }

    &__text {
      font-size: 14px;
      padding: 10px;
    }

    &--sent {
      padding-left: 40px;
      padding-right: 0;
    }
  }

}

.rate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  h4 {
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 40px;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 30px;
  }

  &__stars {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  &__star {
    cursor: pointer;
    padding-right: 5px;

    &:first-child {
      padding-right: 0;
    }

    svg {
      transition: all .1s ease;
    }

  }

  .rate__star:hover svg,
  .rate__star--active svg,
  .rate__star:hover ~ .rate__star svg,
  .rate__star--active ~ .rate__star svg {
    fill: $gold;
  }

  button {
    margin: 0 auto;
  }

  @media screen and (max-width: 1000px) {
    h4 {
      font-size: 24px;
    }

    &__star svg {
      width: 40px;
    }
  }
}

.attached-image {
  cursor: pointer;

  .original-name {
    font-size: 0.5em;
    max-width: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;;
  }

  .image-view {
    max-height: 32px;
    max-width: 32px;
  }
}
